import React, { ReactNode } from 'react'
import {
  documentToReactComponents,
  Options
} from '@contentful/rich-text-react-renderer'
import { Document, BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from 'src/components/shared/Img/contentful'

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (!node.data.target?.sys.id) return null
      return (
        <ContentfulImage
          id={node.data.target?.sys.id}
          filePath={node.data.target?.fields?.file?.['ja-JP']?.url}
        />
      )
    }
  }
}

export const renderContentfulNodes = (data: Document): ReactNode =>
  documentToReactComponents(data, options)
