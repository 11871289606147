import React from 'react'
import styled from 'styled-components'
import { ArticlesPageContext } from 'gatsby/gatsby-node'
import Wrapper from 'src/components/shared/Wrapper'
import { font, lh, mq, color, ma } from 'src/lib/styles/variable'
import { Document } from '@contentful/rich-text-types'
import { ContentfulImage } from 'src/components/shared/Img/contentful'
import { renderContentfulNodes } from 'src/lib/contentful/renderer'

type Props = ArticlesPageContext

const Single: React.FCX<Props> = (props) => {
  return (
    <article className={`${props.className} page-wrapper`}>
      <Wrapper>
        <div className="p-single u-wrapper">
          <div className="p-single-top">
            <h1 className="c-title c-title--color_main">{props.node.title}</h1>
            <time className="p-single-top__time u-text-bold">
              {props.node.published}
            </time>
            <div className="p-single-top__cat u-text-normal">
              {`カテゴリー： ${props.node.category.title}`}
            </div>
          </div>
          <div className="p-single-content u-text-normal">
            <div className="p-single-content__thumbnail">
              <ContentfulImage id={props.node.thumbnail.contentful_id} />
            </div>
            {renderContentfulNodes(JSON.parse(props.node.body.raw) as Document)}
          </div>
        </div>
      </Wrapper>
    </article>
  )
}

export default styled(Single)`
  min-height: calc(100vh - 200px - 85px);
  padding-top: 60px;
  padding-bottom: 60px;
  .p-single {
    &.u-wrapper {
      ${ma};
      max-width: 680px;
    }
    &-top {
      .c-title {
        ${font(22)};
        ${lh(22 * 1.75)};
        margin-bottom: 40px;
        ${mq('tab')} {
          ${font(20)};
          ${lh(22 * 1.75)};
          margin-bottom: 30px;
        }
        ${mq('sp_wide')} {
          ${font(18)};
          margin-bottom: 22px;
        }
      }
      &__time {
        display: block;
        margin-bottom: 10px;
      }

      &__cat {
        display: flex;
        padding-bottom: 30px;
        border-bottom: 1px solid #e5e5e5;
      }
    }

    &-content {
      padding-top: 30px;

      &__thumbnail {
        margin-bottom: 30px;
      }

      white-space: pre-wrap;

      p,
      img {
        margin-bottom: 30px;
        ${mq('tab')} {
          margin-bottom: 22px;
        }
        ${mq('sp_wide')} {
          margin-bottom: 18px;
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      a {
        display: inline-block;
        color: ${color.main};
      }
    }
    .wp-post-image {
      width: 100% !important;
      height: auto !important;
      margin: 0 !important;
      margin-bottom: 30px !important;
    }
  }
`
