import React, { useMemo } from 'react'
import { ArticlesPageContext } from 'gatsby/gatsby-node'
import { DefaultLayout } from 'src/components/layout/default'
import { Head } from '../layout/head'
import Single from '../pages/Single'

type Props = {
  pageContext: ArticlesPageContext
}

const Article: React.FCX<Props> = (props) => {
  const description = useMemo(() => {
    const json = JSON.parse(props.pageContext.node.body.raw)
    const paragraphs = json.content.filter(
      (n) => n.nodeType === 'paragraph'
    )
    const twoParagraphs = paragraphs.slice(1)
    const desc = twoParagraphs.join('\n')
    return desc
  }, [props.pageContext.node.body])

  return (
    <div className="article">
      <Head
        title={props.pageContext.node.title}
        image={props.pageContext.node.thumbnail.file.url}
        description={description}
      />
      <DefaultLayout>
        <Single {...props.pageContext} />
      </DefaultLayout>
    </div>
  )
}

export default Article
