import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ContentfulImageQueryQuery } from 'types/graphql-types'
import styled from 'styled-components'

type Props = {
  filePath?: string
  id: string
}

const Component: React.FCX<Props> = (props) => {
  const data = useStaticQuery<ContentfulImageQueryQuery>(graphql`
    query ContentfulImageQuery {
      allContentfulAsset {
        nodes {
          contentful_id
          gatsbyImageData(placeholder: TRACED_SVG)
          file {
            fileName
            url
          }
        }
      }
    }
  `)

  const target = data.allContentfulAsset.nodes.find((d) => d.contentful_id === props.id)

  if (!target) {
    return <small className="">{`No Image Found ${props.id}`}</small>
  }
  const targetGatsbyImageData = target?.gatsbyImageData

  const image = getImage(targetGatsbyImageData)

  if (image) {
    return <GatsbyImage image={image} alt={target.file.fileName} />
  }

  return <img src={target.file.url} alt={target.file.fileName}/>
}

const StyledComponent = styled(Component)`
  .gatsby-image-wrapper {
    max-width: 100%;
  }
`

export const ContentfulImage = StyledComponent